<template>
  <div class="auth-pushdown">
    <div
      class="auth-pushdown__blackout"
      :class="{ 'auth-pushdown__blackout--active': authActive }"
      @click="handlePushdownClose"
    ></div>
    <div class="auth-pushdown__container" ref="pushdown">
      <Expander
        :key="resetId"
        :expanded="authActive"
        class="auth-pushdown__expander"
      >
        <div
          class="auth-pushdown__content text--center flex--col-center-center"
        >
          <AuthLogin :key="resetId" @resolve="handleResolve" />
          <button
            @click="handlePushdownClose"
            class="auth-pushdown__cancel-button text-body text--underline text--clr-black"
          >
            {{
              UI_ELEMENT(
                authSuccess || authFailure
                  ? "auth_pushdown_close"
                  : "auth_pushdown_cancel"
              )
            }}
          </button>
        </div>
      </Expander>
    </div>
  </div>
</template>

<script>
import AuthLogin from "./AuthLogin.vue"
import Expander from "@/components/ui/Expander.vue"
import { mapMutations, mapState } from "vuex"

import { pushdownLock, pushdownUnlock } from "@/helpers/ScrollLock.js"

export default {
  components: {
    AuthLogin,
    Expander
  },

  data: () => ({
    authActive: false,
    authSuccess: false,
    authFailure: false,
    resetId: null
  }),

  methods: {
    ...mapMutations("scroll", ["SET_DISABLE_BANNER"]),
    ...mapMutations("ui", ["SET_NAV_ACTIVE_ELEMENT"]),

    handlePushdownClose() {
      this.SET_NAV_ACTIVE_ELEMENT(null)
    },

    handleResolve(resolvedState) {
      this[resolvedState] = true
    }
  },

  computed: {
    ...mapState("ui", ["navActiveElement"])
  },

  watch: {
    navActiveElement(newVal) {
      if (newVal == "login") {
        this.resetId = Math.random()
        this.authActive = true
        this.authSuccess = this.authFailure = false
        this.SET_DISABLE_BANNER(true)
        pushdownLock(this.$refs["pushdown"], 50)
      } else {
        this.authActive = false
        this.SET_DISABLE_BANNER(false)
        pushdownUnlock(this.$refs["pushdown"])
      }
    }
  }
}
</script>

<style lang="scss">
.auth-pushdown {
  z-index: 4;
  width: 100%;
  height: 0;

  position: sticky;
  top: $nav-height-default;

  @include media-breakpoint-up(md) {
    top: $nav-height-md;
  }

  @include media-breakpoint-up(lg) {
    top: $nav-height-lg + $menu-height-lg;
  }

  @include media-breakpoint-up(xl) {
    top: $nav-height-xl + $menu-height-lg;
  }

  &__blackout {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s linear;
    background-color: rgba(getcolour(bleach_black), 0.5);

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }

  &__container {
    position: relative;
    top: 0;
    width: 100%;
    margin: 0 auto;
    max-width: $bleach-site-max-width;
    background-color: rgba(getcolour(bleach_white), 0.95);
    backdrop-filter: blur(10px) invert(75%);
    max-height: calc(100vh - #{$nav-height-default});
    padding: 0px 24px;
    overflow-y: auto;

    @include media-breakpoint-up(md) {
      height: auto;
      max-height: calc(100vh - #{$nav-height-md});
    }

    @include media-breakpoint-up(lg) {
      width: 580px;
      max-height: calc(100vh - #{$nav-height-lg + $menu-height-lg});
    }

    @include media-breakpoint-up(xl) {
      max-height: calc(100vh - #{$nav-height-xl + $menu-height-lg});
    }
  }

  &__content {
    max-width: 500px;
    margin: 0 auto;
    padding: 40px 0px;
    min-height: calc(100vh - #{$nav-height-default});

    @include media-breakpoint-up(md) {
      min-height: unset;
      height: auto;
    }
  }

  &__cancel-button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;

    body:not(.mouse-nav) &:focus {
      outline: 2px solid $bleach-peach;
    }
  }
}
</style>
