<template>
  <div class="auth-login">
    <div class="auth-login__content-wrapper flex--col-center-center">
      <template v-if="success">
        <div
          v-if="GET_IS_BELOW_BREAKPOINT('md')"
          class="auth-login__bubbles-wrapper mb-16"
        >
          <HappyBubbles class="auth-login__success-bubbles" :active="success" />
        </div>
        <div
          v-if="GET_IS_BELOW_BREAKPOINT('md')"
          class="auth-login__title text-editorial-super text--clr-black text--uppercase mb-8"
        >
          {{ UI_ELEMENT("auth_login_success_title") }}
        </div>
        <AutoHeading
          v-else
          :tag="`h1`"
          class="text-editorial-super text--uppercase"
          :class="`text--clr-black`"
          :minMax="{ xs: [20, 30] }"
          :lines="1"
          >{{ UI_ELEMENT("auth_login_success_title") }}</AutoHeading
        >
        <div class="auth-login__copy text-body text--clr-black mb-16">
          {{ UI_ELEMENT("auth_login_success_body") }}
        </div>
      </template>
      <template v-else-if="error !== null">
        <div
          v-if="GET_IS_BELOW_BREAKPOINT('md')"
          class="auth-login__title text-editorial-super text--clr-black text--uppercase mb-8"
        >
          {{ UI_ELEMENT("auth_login_error_title") }}
        </div>
        <AutoHeading
          v-else
          :tag="`h1`"
          class="text-editorial-super text--uppercase"
          :class="`text--clr-black`"
          :minMax="{ xs: [20, 30] }"
          :lines="1"
          >{{ UI_ELEMENT("auth_login_error_title") }}</AutoHeading
        >
        <div class="auth-login__copy text-body text--clr-black mb-4">
          {{ UI_ELEMENT("auth_login_error_body") }}
        </div>
        <div
          class="auth-login__copy text-body-small text--w-bold text--clr-black mb-16"
        >
          {{ error }}
        </div>
      </template>
      <template v-else>
        <div
          v-if="GET_IS_BELOW_BREAKPOINT('md')"
          class="auth-login__title text-editorial-super text--clr-black text--uppercase mb-8"
        >
          {{ UI_ELEMENT("auth_login_title") }}
        </div>
        <AutoHeading
          v-else
          :tag="`h1`"
          class="text-editorial-super text--uppercase"
          :class="`text--clr-black`"
          :minMax="{ xs: [20, 30] }"
          :lines="1"
          >{{ UI_ELEMENT("auth_login_title") }}</AutoHeading
        >
        <div class="auth-login__copy text-commerce-intro text--clr-black mb-16">
          {{ UI_ELEMENT("auth_login_body") }}
        </div>
        <div class="auth-login__email">
          <FormInput
            ref="username"
            type="email"
            label=""
            inputId="username"
            v-model="username"
            placeholder="input_placeholder_email"
            autocomplete="email"
            :isError="isFormError && getFormErrorStates.includes('username')"
            :errorMessage="UI_ELEMENT('auth_login_email_error')"
            float
            center
            :disabled="loading"
          />
        </div>
        <Expander
          :expanded="newUserRegistration"
          class="auth-login__new-user-expander"
        >
          <div class="auth-login__copy text-body text--clr-black mb-16">
            {{ UI_ELEMENT("auth_login_more_details") }}
          </div>
          <FormWrapper class="auth-login__name-holder">
            <FormInput
              ref="firstName"
              class="auth-login__name-field"
              type="text"
              label=""
              inputId="firstName"
              v-model="firstName"
              placeholder="input_placeholder_firstname"
              :isError="isFormError && getFormErrorStates.includes('firstName')"
              :errorMessage="UI_ELEMENT('auth_login_firstname_error')"
              autocomplete="off"
              float
              center
              :disabled="loading"
            />
            <FormInput
              ref="lastName"
              class="auth-login__name-field"
              type="text"
              label=""
              inputId="lastName"
              v-model="lastName"
              placeholder="input_placeholder_lastname"
              :isError="isFormError && getFormErrorStates.includes('lastName')"
              :errorMessage="UI_ELEMENT('auth_login_lastname_error')"
              autocomplete="off"
              float
              center
              :disabled="loading"
            />
          </FormWrapper>
          <div class="auth-login__marketing flex--row-center-start">
            <FormInput
              class="bp--login__marketing-checkbox text--clr-black"
              type="checkbox"
              label="auth_marketing_checkbox_label"
              inputId="marketing"
              v-model="subscribedToMarketing"
              :disabled="loading"
            >
            </FormInput>
          </div>
        </Expander>
        <BasicButton
          class="bp-modal-default__button mt-24 mb-16"
          :disabled="
            loading || !hasValidEmail || (newUserRegistration && !hasValidName)
          "
          @click="handleButtonClick"
          >{{
            loading
              ? UI_ELEMENT("async_event_wait")
              : newUserRegistration
              ? UI_ELEMENT("auth_cta_register")
              : UI_ELEMENT("auth_cta_login")
          }}</BasicButton
        >
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import AuthLoginMixin from "./AuthLoginMixin"
import FormMixin from "@/views/mixins/FormMixin.js"
import Expander from "@/components/ui/Expander"
import AutoHeading from "../../components/text/AutoHeading.vue"
import HappyBubbles from "@/components/HappyBubbles.vue"

export default {
  name: "AuthLogin",

  mixins: [AuthLoginMixin, FormMixin],

  components: {
    AutoHeading,
    Expander,
    HappyBubbles
  },

  data: () => ({}),

  methods: {
    handleButtonClick() {
      if (!this.isLoading) {
        if (this.getFormErrorStates.length === 0) {
          if (this.newUserRegistration) {
            this.doSignUp()
          } else {
            this.doIdentify()
          }
        } else {
          this.isFormError = true
          this.goToFirstError()
        }
      }
    }
  },

  computed: {
    ...mapGetters("breakpoints", ["GET_IS_BELOW_BREAKPOINT"]),

    // Every component that uses the FormMixin needs to define the field validators, as below:
    getFormValidators() {
      return {
        username: !this.hasValidEmail,
        firstName: this.newUserRegistration && this.firstName.length === 0,
        lastName: this.newUserRegistration && this.lastName.length === 0
      }
    }
  },

  watch: {
    newUserRegistration(newVal) {
      if (newVal) this.$refs["firstName"].focus()
    }
  }
}
</script>

<style lang="scss">
.auth-login {
  &__content-wrapper {
    position: relative;
    margin: 0 auto;
    max-width: 700px;
  }

  &__email {
    width: 100%;
  }

  &__button {
    margin: 30px auto;

    @include media-breakpoint-up(md) {
      margin: 40px auto;
    }
  }

  &__new-user-expander {
    width: 100%;
  }

  &__name-holder {
    width: 100%;

    @include media-breakpoint-up(md) {
      display: flex;
      column-gap: 16px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__name-field {
    position: relative;
    flex: 1 1 50%;
  }

  &__marketing {
    width: 100%;
  }

  &__bubbles-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    margin-top: -50%;
    overflow: hidden;
  }

  &__success-bubbles {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
