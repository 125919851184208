import { validateEmail } from "@/utils"
import { mapActions } from "vuex"

export default {
  data: () => ({
    username: "",
    firstName: "",
    lastName: "",
    usernameExists: false,
    newUserRegistration: false,
    subscribedToMarketing: false,
    loading: false,
    success: false,
    error: null
  }),

  methods: {
    ...mapActions("identity", ["identityEmailSignIn", "identityEmailSignUp"]),

    doIdentify() {
      this.loading = true

      this.identityEmailSignIn({
        username: this.username,
        postLogInRoute: this.$route.query.redirectSource
      })
        .then(() => {
          this.loading = false
          this.success = true
        })
        .catch(e => {
          this.loading = false
          if (e.code == "UserNotFoundException") {
            this.newUserRegistration = true
          } else {
            this.error = e.code
            this.debug.error("AUTH ERROR:", e)
          }
        })
    },

    doSignUp() {
      this.loading = true
      this.usernameExists = false

      this.identityEmailSignUp({
        username: this.username,
        firstName: this.firstName,
        lastName: this.lastName,
        subscribedToMarketing: this.subscribedToMarketing,
        postLogInRoute: this.$route.query.redirectSource
      })
        .then(() => {
          this.loading = false
          this.success = true
        })
        .catch(e => {
          this.loading = false
          this.error = e.code
          this.debug.error("AUTH ERROR:", e)
        })
    }
  },

  computed: {
    hasValidEmail() {
      return validateEmail(this.username)
    },

    hasValidName() {
      return this.firstName.length > 0 && this.lastName.length > 0
    }
  },

  watch: {
    username() {
      this.newUserRegistration = false
    },

    success(newVal) {
      if (newVal) {
        this.$emit("resolve", "authSuccess")
      }
    },

    error(newVal) {
      if (newVal) {
        this.$emit("resolve", "authFailure")
      }
    }
  }
}
